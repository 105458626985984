import { graphql } from 'gatsby';
import React from 'react'
import Layout from '../components/Layout'
import ProductGrid from '../components/ProductGrid';

const category = (props) => {
    const productCollection = props.data.allProducts.nodes;
    return (
        <Layout hasSlider={false}>
            <div className="h-fit py-6 pl-12">
                <p className='font-semibold text-xl'>{ productCollection[0]?.category }</p>
                <hr className='w-full mt-2 mb-8 border-gray-200 border-x-2'></hr>
                <ProductGrid products={ productCollection } />
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query CategoryProducts($categoryId: String) {
        allProducts(filter: {category: {eq: $categoryId}}, sort: {fields: name}) {
            nodes {
                sku
                name
                price
                category
                slug
            }
        }
    }
`

export default category;